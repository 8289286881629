import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { Table, Button } from 'react-bootstrap'
import { getWSEBookUser, getEBookSSO } from '../../actions/solidworksAction'
import { EBOOK_SSO_CREATE_RESET } from '../../constants/solidworksConstants'
import queryString from 'query-string'
import PPPTable from '../../components/PPPTable'

const SolidWorksEBookScreen = ({ match, history, location }) => {
  const dispatch = useDispatch()
  const params = queryString.parse(location.search)
  const dbname = params.dbname
  const token = params.token || match.params.token
  const eBookGet = useSelector((state) => state.getWSEBookUser)
  const SSO = useSelector((state) => state.getEBookSSO)
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, success, ebooks } = eBookGet
  const { loading: loadingSSO, url, error: ErrorSSO } = SSO
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/user/login' + (dbname ? `?dbname=${dbname}` : ''))
    }
    if ((!ebooks && !loading) || (ebooks && ebooks.success === true && ebooks.data.Email.toLowerCase() !== userInfo.loginname.toLowerCase())) {
      dispatch(getWSEBookUser())
    }
    if (!loadingSSO && url && url.URL) {
      window.open(url.URL)
      dispatch({ type: EBOOK_SSO_CREATE_RESET })
    }
  }, [
    dispatch,
    dbname,
    userInfo,
    token,
    loading,
    error,
    success,
    ebooks,
    loadingSSO,
    url,
    ErrorSSO,
    history,
  ])

  const linkHandler = (docid) => {
    dispatch(getEBookSSO(docid))
  }

  return (
    <>
      <h2>SOLIDWORKS EBooks</h2>
      {error && (
        <Message variant='danger' dismissible={true}>
          {error}
        </Message>
      )}
      {loading && !ebooks ? (
        <Loader />
      ) : (
        ebooks &&
        ebooks.success && (
          <>
            <PPPTable report={ebooks.data.report} onLink={linkHandler} />

            <Table
              striped
              bordered
              hover
              responsive
              className='table-sm'
              hidden
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Web Viewer</th>
                </tr>
              </thead>
              <tbody>
                {ebooks.data.Books.map((ebook) => (
                  <tr>
                    <td>
                      <Button
                        variant='link'
                        onClick={() => {
                          linkHandler(ebook.ID)
                        }}
                      >
                        {ebook.Name}
                      </Button>
                    </td>
                    <td>{ebook.ProductID}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )
      )}
    </>
  )
}

export default SolidWorksEBookScreen
